<template>
	<div class="votepage">

		<div>

				<div @click="clickFun" class="fullscreen">{{ isFullFlag ? '退出全屏' : '全屏' }}</div>
				<!-- <div @click="clickFun1">{{ isFullFlag1 ? '退出部分全屏' : '部分全屏' }}</div> -->
		
			<!--需要全屏展示的内容-->
			<div id="content">
				<vote-bar :data="bardata" @updateData="updateData" :showField="['acreage','buildacreage', 'totalprice']"></vote-bar>
			</div>
		</div>
	</div>
</template>

<script>
import screenfull from 'screenfull'
import {countVote} from '@/api/website/vote.js'
import VoteBar from '../../components/echarts/VoteBar.vue';

export default {
	components: { VoteBar },


	data() {
    return {
			bardata:[0,0,0,0],
      isFullFlag: false,
      isFullFlag1: false,
    }
  },
	mounted() {
    // 监听页面全屏
    window.addEventListener("fullscreenchange", (e) => {
      console.log(screenfull.isFullscreen)
      this.isFullFlag = screenfull.isFullscreen
    })
  },
	watch: {

	},

	methods: {
		updateData(){
			countVote().then(res => {
				let bardata = []
				 res.data.map(item=>{
					bardata.push(item.num)
				})
				console.log('bardata',bardata)
				this.bardata = bardata
			})
			// this.bardata = [Math.random()*100, Math.random()*100, Math.random()*100, Math.random()*100]
		},

		clickFun() {
      this.isFullFlag = !this.isFullFlag
      console.log(screenfull)
      if (!screenfull.enabled) {
        this.$message({
          message: 'Your browser does not work',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    clickFun1() {
      this.isFullFlag1 = !this.isFullFlag1
      const element = document.getElementById('content');//指定全屏区域元素
      if (this.isFullFlag1) {
        // screenfull.request(element);
        element.requestFullscreen()
      } else {
        document.exitFullscreen();
      }
    }
	}
};
</script>

<style lang="scss" scoped>
.votepage {
	width: 100%;
	height: 100vh;
	background: #fff;
}
.fullscreen{
	color:#666;
}

#content{
	margin-top:60px;
}
.img {
	width: 700px;
}
</style>
