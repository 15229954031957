<template>
  <v-chart v-if="!hidechart" :class="{ 'chart': true, 'width100': width100 }" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";

import { BarChart } from "echarts/charts";
import { PieChart, xAxis, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { read } from "fs";
import { json } from "body-parser";

use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: "bar",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },

  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },

    width100: {
      type: Boolean,
      default: false
    },
    showField: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  emits: ['updateData'],
  mounted() {
    this.createOption()
  },


  watch: {
    // data(nv, ov) {
    //   if (JSON.stringify(nv) === '{}' || JSON.stringify(nv) === '[]' || JSON.stringify(nv) === '') {
    //     this.hidechart = true
    //   } else {
    //     this.hidechart = false
    //     this.option = {};
    //     this.createOption();
    //   }


    // },
    showField(nv) {
      this.defaultField = nv;
    },
  },

  data() {
    return {
      hidechart: false,
      defaultField: [],
      option: {},
    };
  },

  methods: {
     run() {
        // for (var i = 0; i < data.length; ++i) {
        //   if (Math.random() > 0.9) {
        //     data[i] += Math.round(Math.random() * 1);
        //   } else {
        //     data[i] += Math.round(Math.random() * 1);
        //   }
        // }
        this.$emit('updateData')


        const data = this.data
        // myChart.setOption({
        this.option.series = [
          {

            realtimeSort: true,
            name: '无锡市惠山区地块规划方案实时投票结果',
            type: 'bar',
            data: data,
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }

          }
        ]
  // });
},

createOption() {

  console.log('option', '1111')

  const data = this.data

  const option = {
    xAxis: {
      max: 'dataMax'
    },
    yAxis: {
      type: 'category',
      data: ['A', 'B', 'C', 'D'],
      inverse: true,
      animationDuration: 300,
      animationDurationUpdate: 300,
      max: 3 // only the largest 4 bars will be displayed
    },
    series: [
      {
        realtimeSort: true,
        name: '无锡市惠山区地块规划方案实时投票结果',
        type: 'bar',
        data: data,
        label: {
          show: false,
          position: 'right',
          valueAnimation: true
        }
      }
    ],
    legend: {
      show: true
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear'
  };
 


  // setTimeout(function () {
  //   run();
  // }, 0);
  
  console.log('option', option)
  this.option = option;
  var that = this
  setInterval(function () {
    that.run()
    console.log('00000000')
  }, 1000);


  // } 
},

labelOption() {
  return {
    show: false,
    rotate: 75,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottomLeft",
    distance: 8,
    formatter: "{c}  {name|{a}}",
    fontSize: 12,
    rich: {
      name: {},
    },
  };
},
  },
};
</script>

<style scoped>
.chart {
  width: 90%;
  height: 800px;
  /* width:100% */
}

.width100 {
  width: 90%;
  height: 800px;
}
</style>